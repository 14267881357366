
import { defineComponent, ref } from "vue";

interface Filter {
  name: string;
  short_key: string;
  status: string | number;
  website: string;
}

export default defineComponent({
  name: "dropdown-1",
  components: {},
  emits: ["submit-filter", "reset-filter"],
  setup(props, { emit }) {
    const formRef = ref<null | HTMLFormElement>(null);
    const data = ref<Filter>({
      name: "",
      short_key: "",
      status: "",
      website: "",
    });

    const submit = () => {
      emit("submit-filter", data.value);
    };

    const handleReset = () => {
      formRef.value?.resetFields();
      emit("reset-filter");
    };

    return {
      data,
      formRef,
      submit,
      handleReset,
    };
  },
});
