
import { defineComponent, ref, onMounted } from "vue";
import { MBDatatable } from "magicbean-saas-common";
import addBrandModal from "@/views/catalog/brands/AddBrandModal.vue";
import FilterDropdwon from "@/views/catalog/brands/FilterDropdown.vue";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { ApiBrands } from "@/core/api";
import _ from "lodash";

interface Brands {
  id: number;
  logo: string;
  name: string;
  status: number;
  description: string;
}

interface SortOption {
  field: string;
  direction: string;
}

interface FilterOption {
  field: string;
  value: string;
  from?: string;
  to?: number;
  condition?: string;
}

export default defineComponent({
  name: "Brands",
  components: {
    MBDatatable,
    addBrandModal,
    FilterDropdwon,
  },
  setup() {
    const loading = ref(true);
    const tableData = ref<Array<Brands>>([]);
    const currentPage = ref<number>(1);
    const total = ref<number>(1);
    const pageSize = ref<number>(10);
    const search = ref<string>("");
    const sortOptions = ref<Array<SortOption>>([]);
    const filterOptions = ref<Array<FilterOption>>([]);

    const tableHeader = ref([
      {
        name: "Brand ID",
        key: "id",
        sortable: true,
      },
      {
        name: "Brand Name",
        key: "name",
        sortable: true,
      },
      {
        name: "Seo Title",
        key: "seo_title",
        sortable: true,
      },
      {
        name: "Status",
        key: "status",
        sortable: true,
      },

      {
        name: "Actions",
        key: "actions",
      },
    ]);

    onMounted(() => {
      MenuComponent.reinitialization();
      setCurrentPageTitle("Brands");
      getBrandsList(
        currentPage.value,
        pageSize.value,
        search.value,
        sortOptions.value,
        filterOptions.value
      );
    });

    const getBrandsList = (
      page: number,
      pageSize: number,
      search?: string,
      sortOptions?: Array<SortOption>,
      filterOptions?: Array<FilterOption>
    ) => {
      loading.value = true;
      ApiBrands.getBrandsList({
        page: page,
        page_size: pageSize,
        search_value: search,
        sort_orders: sortOptions,
        filter_group: filterOptions,
      })
        .then(({ data }) => {
          loading.value = false;
          if (data.code == 0) {
            tableData.value.splice(
              0,
              tableData.value.length,
              ...data.data.items
            );
            total.value = data.data.total;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const onCurrentPageChange = (page) => {
      currentPage.value = page;
      getBrandsList(
        page,
        pageSize.value,
        search.value,
        sortOptions.value,
        filterOptions.value
      );
    };

    const onRowsPerPageChange = (pagesize) => {
      pageSize.value = pagesize;
      getBrandsList(
        currentPage.value,
        pagesize,
        search.value,
        sortOptions.value,
        filterOptions.value
      );
    };
    const handleFilter = (val) => {
      let filterArr: Array<FilterOption> = [];
      for (let item in val) {
        if (val[item] != "") {
          filterArr.push({
            field: item,
            value: val[item],
            condition: "like",
          });
        }
      }
      filterOptions.value.splice(0, filterOptions.value.length, ...filterArr);
      getBrandsList(
        currentPage.value,
        pageSize.value,
        search.value,
        sortOptions.value,
        filterOptions.value
      );
    };

    const handleFilterReset = () => {
      resetFilter();
      getBrandsList(
        currentPage.value,
        pageSize.value,
        search.value,
        sortOptions.value,
        filterOptions.value
      );
    };

    const resetFilter = () => {
      filterOptions.value.splice(0, filterOptions.value.length);
    };

    const onColumnSort = (val) => {
      let sortObj: SortOption = {
        field: val.columnName,
        direction: val.order,
      };
      sortOptions.value.splice(0, sortOptions.value.length, sortObj);
      getBrandsList(
        currentPage.value,
        pageSize.value,
        search.value,
        sortOptions.value,
        filterOptions.value
      );
    };

    const debounceSearch = _.debounce(getBrandsList, 1000);

    const searchItems = () => {
      debounceSearch(
        currentPage.value,
        pageSize.value,
        search.value,
        sortOptions.value,
        filterOptions.value
      );
    };

    const UpdatesList = () => {
      getBrandsList(
        currentPage.value,
        pageSize.value,
        search.value,
        sortOptions.value,
        filterOptions.value
      );
    };

    return {
      loading,
      tableHeader,
      tableData,
      currentPage,
      total,
      pageSize,
      search,
      getBrandsList,
      onCurrentPageChange,
      onRowsPerPageChange,
      searchItems,
      onColumnSort,
      handleFilter,
      handleFilterReset,
      UpdatesList,
    };
  },
});
